//
//
//
//
//
//

export default {
  name: "ShowPostsListsButton",
  methods: {
    showPostListComponent () {
      this.$nuxt.$emit('show-posts-list-mode')
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'pagination',
  auth: false,
  props: {
    data: Object
  },
  methods: {
    getPrevPageQuery() {
      const prev = this.getPrev()
      if(prev === 1) {
        return null
      } else {
        return { page: this.getPrev() }
      }
    },
    getPrev() {
      if ((this.data.current_page - 1) >= 1) {
        return (this.data.current_page - 1)
      } else {
        return this.data.current_page
      }
    },
    getNext() {
      if ((this.data.current_page + 1) <= this.data.last_page) {
        return (this.data.current_page + 1)
      } else {
        return this.data.current_page
      }
    },
    getCurrentPage() {
      return this.data.current_page
    },
    getPrevPageWithDiff(minus = 1) {
      if (this.data.current_page - minus >= 1) {
        return (this.data.current_page - minus)
      } else {
        return null
      }
    },
    getNextPageWithDiff(plus = 1) {
      if (this.data.current_page + plus <= this.data.last_page) {
        return (this.data.current_page + plus)
      } else {
        return null
      }
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Breadcrumbs',
  props: {
    links: Array
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PhoenixLogotype',
  props: {
    width: {
      default: 300,
      type: Number
    },
    height: {
      default: 300,
      type: Number
    },
  }
}
